var no = {
    startChatContainer: {
        title: 'Vi vil gjerne hjelpe deg!',
        subTitle: 'Start chat og få hjelp.',
        startChatButton: 'Start chat',
    },
};
var en = {
    startChatContainer: {
        title: 'We will gladly help you!',
        subTitle: 'Start chat and get help.',
        startChatButton: 'Start chat',
    },
};
export var messages = {
    no: no,
    en: en,
};
