import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", null, [
        _createElementVNode("div", {
            class: "bubble",
            "aria-expanded": "true",
            onClick: _cache[0] || (_cache[0] = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                return (_ctx.showMessageClick && _ctx.showMessageClick.apply(_ctx, args));
            })
        })
    ]));
}
