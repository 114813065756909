import { defineComponent } from 'vue';
export default defineComponent({
    name: 'StartChatMessage',
    components: {},
    props: {},
    data: function () {
        return {};
    },
    created: function () { },
    methods: {
        hideMessageClick: function () {
            this.$emit('message:close');
        },
        startChatClick: function () {
            this.$emit('message:join');
        },
    },
});
