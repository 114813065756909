import { mount } from './genesysChatBootstrap';
var genesysChatDiv = document.getElementById('genesysChat');
if (genesysChatDiv) {
    var locale = document.documentElement.lang;
    var marketArea = document.documentElement.getAttribute('data-market') || 'no';
    var environment = document.documentElement.getAttribute('data-environment');
    var deploymentKey = '1374ff13-17f6-4c89-9d75-928b58b57241'; // Staging queue
    if (marketArea === 'no' && environment === 'prod') {
        deploymentKey = '287fccd7-f05f-449c-961a-729add79af23'; // Production queue
    }
    mount(genesysChatDiv, deploymentKey, locale);
}
