import { defineComponent } from 'vue';
import Bubble from './Bubble.vue';
import StartChatMessage from './StartChatMessage.vue';
import { EventInfoAction, EventInfoCategory, trackTradidionalGaEvent, } from '@/components/GoogleTagManager';
export default defineComponent({
    name: 'GenesysChatApp',
    components: {
        Bubble: Bubble,
        StartChatMessage: StartChatMessage,
    },
    props: {
        locale: { type: String, required: true },
        chatConfig: { type: Object, required: true },
        autoJoin: { type: Boolean, required: false, default: false },
    },
    data: function () {
        return {
            showBubble: true,
            showMessage: false,
        };
    },
    mounted: function () {
        this.mountChat();
    },
    methods: {
        mountChat: function () {
            if (this.autoJoin) {
                this.showBubble = false;
            }
        },
        startChat: function () {
            var _this = this;
            this.showBubble = false;
            this.showMessage = false;
            var chatContainerDiv = document.getElementById('genesys-chat-container');
            window.ININ.webchat
                .create(this.chatConfig)
                .then(function (webchat) {
                chatContainerDiv.style.display = 'block';
                if (!webchat.isAutoJoined()) {
                    webchat.renderFrame({
                        containerEl: 'genesys-chat-container',
                    });
                }
                webchat.chatEnded = function () {
                    chatContainerDiv.style.display = 'none';
                    // Buble will show again when user reloads the page (if he wants to chat again after chat has ended)
                };
            })
                .catch(function (error) {
                console.error('Error initializing chat');
                console.error(error);
                _this.showMessage = false;
                _this.showBubble = false;
            });
        },
        startChatClick: function () {
            trackTradidionalGaEvent(EventInfoCategory.GenesysChat, EventInfoAction.Button, 'Start Chat');
            this.startChat();
        },
        showMessageClick: function () {
            trackTradidionalGaEvent(EventInfoCategory.GenesysChat, EventInfoAction.Button, 'Show start chat message');
            this.showMessage = true;
        },
        hideMessageClick: function () {
            trackTradidionalGaEvent(EventInfoCategory.GenesysChat, EventInfoAction.Button, 'Hide start chat message');
            this.showMessage = false;
        },
    },
});
