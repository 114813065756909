import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { messages } from './Locale/getTexts';
// Vuetify
import { createVuetify } from 'vuetify';
import GeneysChatApp from './GenesysChatApp.vue';
import { EventInfoAction, EventInfoCategory, trackTradidionalGaEvent, } from '@/components/GoogleTagManager';
function onScriptLoad(appDiv, chatConfig, locale) {
    var chatContainerDiv = document.createElement('div');
    chatContainerDiv.id = 'genesys-chat-container';
    chatContainerDiv.className = 'genesys-chat-container';
    document.body.appendChild(chatContainerDiv);
    window.ININ.webchat
        .create(chatConfig)
        .then(function (webchat) {
        var autoJoin = webchat.isAutoJoined();
        if (autoJoin) {
            trackTradidionalGaEvent(EventInfoCategory.GenesysChat, EventInfoAction.Displayed, 'AutoJoined');
            if (chatContainerDiv) {
                chatContainerDiv.style.display = 'block';
            }
        }
        webchat.chatEnded = function () {
            chatContainerDiv.style.display = 'none';
            trackTradidionalGaEvent(EventInfoCategory.GenesysChat, EventInfoAction.Button, 'ChatEnded');
            // Buble will show again when user reloads the page (if he wants to chat again after chat has ended)
        };
        var i18n = createI18n({
            legacy: false,
            locale: locale,
            fallbackLocale: 'en',
            messages: messages,
        });
        var vuetify = createVuetify({
            theme: false,
        });
        var app = createApp(GeneysChatApp, {
            locale: locale,
            autoJoin: webchat.isAutoJoined(),
            chatConfig: chatConfig,
        });
        app.use(vuetify);
        app.use(i18n);
        app.mount(appDiv);
    })
        .catch(function (error) {
        console.log('Error initializing chat' + error);
        trackTradidionalGaEvent(EventInfoCategory.GenesysChat, EventInfoAction.None, 'Error initializing chat');
    });
}
export var mount = function (mountDiv, deploymentKey, locale) {
    // If deploymentKey is empty, don't load the script
    if (deploymentKey === '') {
        return;
    }
    var orgGuid = '2f3fefb3-1b1d-44b7-ad2f-f3ad0b90ebb5';
    var script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', 'https://apps.mypurecloud.ie/webchat/jsapi-v1.js');
    script.setAttribute('id', 'purecloud-webchat-js');
    script.setAttribute('region', 'eu-west-1');
    script.setAttribute('org-guid', orgGuid);
    script.setAttribute('deployment-key', deploymentKey); // queue (production or staging is available)
    script.onload = function () { return onScriptLoad(mountDiv, chatConfig, locale); }; // callback function that runs when script is loaded
    document.body.appendChild(script);
    var chatConfig = {
        webchatAppUrl: 'https://apps.mypurecloud.ie/webchat',
        webchatServiceUrl: 'https://realtime.mypurecloud.ie:443',
        reconnectEnabled: true,
        reconnectOrigins: [
            'https://www.skagenfondene.no',
            'https://investor.skagenfondene.no',
            'http://localhost:4000',
        ],
        orgGuid: orgGuid,
        locale: locale,
    };
    // Required if reconnects are enabled
    window.PURECLOUD_WEBCHAT_FRAME_CONFIG = {
        containerEl: 'genesys-chat-container',
    };
};
