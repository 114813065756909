import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = { class: "genesys-banner vngage-active chat-expand" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_Bubble = _resolveComponent("Bubble");
    var _component_StartChatMessage = _resolveComponent("StartChatMessage");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.showBubble)
            ? (_openBlock(), _createBlock(_component_Bubble, {
                key: 0,
                locale: _ctx.locale,
                "onMessage:show": _ctx.showMessageClick
            }, null, 8 /* PROPS */, ["locale", "onMessage:show"]))
            : _createCommentVNode("v-if", true),
        (_ctx.showMessage)
            ? (_openBlock(), _createBlock(_component_StartChatMessage, {
                key: 1,
                locale: _ctx.locale,
                "onMessage:close": _ctx.hideMessageClick,
                "onMessage:join": _ctx.startChatClick
            }, null, 8 /* PROPS */, ["locale", "onMessage:close", "onMessage:join"]))
            : _createCommentVNode("v-if", true)
    ]));
}
